// clipboard.js

'use strict';

const clipboard = document.querySelector('[data-clipboard-target]');

if(clipboard) {
    import(/* webpackChunkName: 'clipboard' */ 'clipboard')
    .then(({default: ClipboardJS}) => {
        let clipboardEl = new ClipboardJS('.clipboard');

        clipboardEl.on('success',(e) => {
            let copyBtn = e.trigger;
            e.clearSelection();

            let tooltip = Tooltip.getInstance(copyBtn);
            tooltip.setContent({'.tooltip-inner': 'Copied!'});

            setTimeout(() => {
                tooltip.hide();
                tooltip.setContent({'.tooltip-inner': copyBtn.dataset.bsTitle});
            }, 1000);

        });

        window.ClipboardJS = ClipboardJS;
    })
    .catch(console.warn);
}